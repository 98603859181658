import React from 'react'
import ReactDOM from 'react-dom/client'

import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

import BuyTickets from './BuyTickets'
import Confirmation from './Confirmation'
import Voucher from './Voucher'

import { createBrowserRouter, RouterProvider } from 'react-router-dom'

// Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'

const firebaseConfig = {
  apiKey: 'AIzaSyDsVSzHMz6wQ4NXQtANvinfLgPa8QZ87xo',
  authDomain: 'entradas-parque-china.firebaseapp.com',
  projectId: 'entradas-parque-china',
  storageBucket: 'entradas-parque-china.appspot.com',
  messagingSenderId: '35544825634',
  appId: '1:35544825634:web:660fe974e9028bc5fb6f7b'
}

const firebaseApp = initializeApp(firebaseConfig)
const db = getFirestore(firebaseApp)

const router = createBrowserRouter([
  {
    path: '/',
    element: <BuyTickets db={db} />
  },
  {
    path: '/confirmacion',
    element: <Confirmation db={db} />
  },
  {
    path: '/comprobante',
    element: <Voucher db={db} />
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)
