import React from 'react'
import { Page, Image, Text, View, Document, Font } from '@react-pdf/renderer'
import logo from './parquechina_pdf.png'
import openSansRegular from './fonts/open-sans-regular.ttf'
import openSans600 from './fonts/open-sans-600.ttf'

const VoucherPDF = ({ ticketQRDataURL, ticketData }) => {
  const datetimeFormat = (date) => {
    const year = date.getFullYear()
    let month = date.getMonth() + 1
    let day = date.getDate()

    if (month < 10) month = '0' + month
    if (day < 10) day = '0' + day

    let hours = date.getHours()
    let minutes = date.getMinutes()

    if (hours < 10) hours = '0' + hours
    if (minutes < 10) minutes = '0' + minutes

    return `${year}-${month}-${day} ${hours}:${minutes}`
  }

  const paymentTypeName = (paymentTypeCode) => {
    let webpayPaymentTypeName = ''

    switch (paymentTypeCode) {
      case 'VD':
        webpayPaymentTypeName = 'Venta Débito'
        break
      case 'VN':
        webpayPaymentTypeName = 'Venta Normal'
        break
      case 'VC':
        webpayPaymentTypeName = 'Venta en cuotas'
        break
      case 'SI':
        webpayPaymentTypeName = '3 cuotas sin interés'
        break
      case 'S2':
        webpayPaymentTypeName = '2 cuotas sin interés'
        break
      case 'NC':
        webpayPaymentTypeName = 'N Cuotas sin interés'
        break
      case 'VP':
        webpayPaymentTypeName = 'Venta Prepago'
        break
    }

    return webpayPaymentTypeName
  }

  Font.register({
    family: 'Open Sans',
    fonts: [
      { src: openSansRegular },
      { src: openSans600, fontWeight: 600 }
    ]
  })

  return (
    <Document>
      <Page size="LETTER" style={{ padding: 20 }}>
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 1 }}>
          </View>
          <View style={{ flex: 2 }}>
            <Image src={logo} style={{ width: '100%' }}/>
            <Text style={{ fontFamily: 'Open Sans', fontWeight: 600, fontSize: 22, textAlign: 'center', marginTop: 10 }}>
             Entradas
            </Text>
          </View>
          <View style={{ flex: 1 }}>
          </View>
        </View>

        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '20' }}>
          <View style={{ textAlign: 'center' }}>
            <Image src={ticketQRDataURL} style={{ width: '150px' }} />
          </View>
        </View>

        <View style={{ marginLeft: '80', fontFamily: 'Open Sans', fontSize: 14 }}>
          <View style={{ flexDirection: 'row', marginTop: 15 }}>
            <Text style={{ flex: 1, fontWeight: 600 }}>Cliente</Text>
            <Text style={{ flex: 1 }}>{`${ticketData.firstName} ${ticketData.lastName}`}</Text>
          </View>

          <View style={{ flexDirection: 'row', marginTop: 15 }}>
            <Text style={{ flex: 1, fontWeight: 600 }}>Fecha</Text>
            <Text style={{ flex: 1 }}>{ticketData.date}</Text>
          </View>

          <View style={{ flexDirection: 'row', marginTop: 15 }}>
            <Text style={{ flex: 1, fontWeight: 600 }}>Número de entradas</Text>
            <Text style={{ flex: 1 }}>{ticketData.numberOfTickets}</Text>
          </View>
        </View>

        <View style={{ marginVertical: 25 }}>
          <Text style={{ fontFamily: 'Open Sans', fontWeight: 600, fontSize: 16, textAlign: 'center' }}>
            Transacción Exitosa
          </Text>
        </View>

        <View style={{ marginLeft: '80', fontFamily: 'Open Sans', fontSize: 14 }}>
          <View style={{ flexDirection: 'row', marginTop: 15 }}>
            <Text style={{ flex: 1, fontWeight: 600 }}>Fecha de transacción</Text>
            <Text style={{ flex: 1 }}>{
              `${datetimeFormat(new Date(ticketData.webpay_plus_response.transaction_date))}`
            }</Text>
          </View>

          <View style={{ flexDirection: 'row', marginTop: 15 }}>
            <Text style={{ flex: 1, fontWeight: 600 }}>Orden de compra</Text>
            <Text style={{ flex: 1 }}>{ticketData.webpay_plus_response.buy_order}</Text>
          </View>

          <View style={{ flexDirection: 'row', marginTop: 15 }}>
            <Text style={{ flex: 1, fontWeight: 600 }}>Tipo de pago</Text>
            <Text style={{ flex: 1 }}>{paymentTypeName(ticketData.webpay_plus_response.payment_type_code)}</Text>
          </View>

          <View style={{ flexDirection: 'row', marginTop: 15 }}>
            <Text style={{ flex: 1, fontWeight: 600 }}>Monto</Text>
            <Text style={{ flex: 1 }}>{new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(ticketData.webpay_plus_response.amount)}</Text>
          </View>

          <View style={{ flexDirection: 'row', marginTop: 15 }}>
            <Text style={{ flex: 1, fontWeight: 600 }}>Número de cuotas</Text>
            <Text style={{ flex: 1 }}>{ticketData.webpay_plus_response.installments_number}</Text>
          </View>

          <View style={{ flexDirection: 'row', marginTop: 15 }}>
            <Text style={{ flex: 1, fontWeight: 600 }}>Monto de cuota</Text>
            <Text style={{ flex: 1 }}>{ticketData.webpay_plus_response.installments_amount ? new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(ticketData.webpay_plus_response.installments_amount) : ''}</Text>
          </View>

          <View style={{ flexDirection: 'row', marginTop: 15 }}>
            <Text style={{ flex: 1, fontWeight: 600 }}>Código de autorización</Text>
            <Text style={{ flex: 1 }}>{ticketData.webpay_plus_response.authorization_code}</Text>
          </View>

          <View style={{ flexDirection: 'row', marginTop: 15 }}>
            <Text style={{ flex: 1, fontWeight: 600 }}>Número de tarjeta</Text>
            <Text style={{ flex: 1 }}>{ticketData.webpay_plus_response.card_detail.card_number}</Text>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default VoucherPDF
