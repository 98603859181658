import { useEffect, useState } from 'react'

import { doc, getDoc } from 'firebase/firestore'

import QRCode from 'qrcode'

import { isMobile } from 'react-device-detect'
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer'
import VoucherPDF from './VoucherPDF'

function Voucher ({ db }) {
  const searchParams = new URLSearchParams(document.location.search)
  const ticketId = searchParams.get('id')
  const dateURL = searchParams.get('dt')
  const emailURL = searchParams.get('em')

  const [showLoading, setShowLoading] = useState(false)

  const [ticketQRDataURL, setTicketQRDataURL] = useState(null)
  const [ticketData, setTicketData] = useState(null)

  useEffect(() => {
    const fetchTicketFirebaseData = async (id) => {
      setShowLoading(true)
      const ticketRef = doc(db, 'tickets', id)
      const ticketDoc = await getDoc(ticketRef)

      if (ticketDoc.data().date === dateURL && ticketDoc.data().email === emailURL) {
        setTicketData(ticketDoc.data())

        const qrDataURL = QRCode.toDataURL(id)
        setTicketQRDataURL(qrDataURL)
      } else {
        window.location.href = '/'
      }

      setShowLoading(false)
    }

    if (ticketId) {
      fetchTicketFirebaseData(ticketId)
    }
  }, [ticketId])

  return (
    <>
      {showLoading && (
        <>
          <div className="container pt-3" style={{ maxWidth: '992px' }}>
            <div className="card">
              <div className='card-title d-flex justify-content-center align-items-center'>
                <img src="parquechina.png" alt="..."
                  className="card-img-top"
                  style={{ objectFit: 'cover', height: '150px' }} />
                <img src="parquechina_logo.svg" alt="..."
                  className='p-2 rounded-lg'
                  style={{ position: 'absolute', backdropFilter: 'blur(10px)', height: '90px', maxWidth: '90%' }} />
              </div>
              <div className="card-body">
                <div className="d-flex justify-content-center align-items-center">
                  <h2 style={{ color: '#dd3030' }}>
                    Cargando...
                  </h2>
                  <div className="spinner-border ms-4" role="status"
                    style={{ color: '#dd3030' }}>
                    <span className="visually-hidden">Cargando...</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {isMobile && ticketData && (
        <>
          <PDFDownloadLink document={
            <VoucherPDF ticketQRDataURL={ticketQRDataURL} ticketData={ticketData}/>
          } fileName={`comprobante-${ticketId}.pdf`}>
          {({ blob, url, loading, error }) => {
            return (
              <div className="container pt-3" style={{ maxWidth: '992px' }}>
                <div className="card">
                  <div className='card-title d-flex justify-content-center align-items-center'>
                    <img src="parquechina.png" alt="..."
                      className="card-img-top"
                      style={{ objectFit: 'cover', height: '150px' }} />
                    <img src="parquechina_logo.svg" alt="..."
                      className='p-2 rounded-lg'
                      style={{ position: 'absolute', backdropFilter: 'blur(10px)', height: '90px', maxWidth: '90%' }} />
                  </div>
                  <div className="card-body">
                    <button className="btn btn-lg btn-primary w-100"
                      style={{ backgroundColor: '#dd3030', borderColor: '#dd3030' }}>
                      Descargar comprobante
                    </button>
                  </div>
                </div>
              </div>
            )
          }}
          </PDFDownloadLink>
        </>
      )}

      {!isMobile && ticketData && (
        <>
          <PDFViewer style={{ height: '100%', position: 'absolute', left: '0px', width: '100%', overflow: 'hidden' }}>
            <VoucherPDF ticketQRDataURL={ticketQRDataURL} ticketData={ticketData}/>
          </PDFViewer>
        </>
      )}
    </>
  )
}

export default Voucher
