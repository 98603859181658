import { useEffect, useState } from 'react'

import { doc, getDoc } from 'firebase/firestore'

function Confirmation ({ db }) {
  const searchParams = new URLSearchParams(document.location.search)
  const ticketId = searchParams.get('id')
  const dateURL = searchParams.get('dt')
  const emailURL = searchParams.get('em')

  const [showLoading, setShowLoading] = useState(false)

  const [ticketData, setTicketData] = useState(null)

  useEffect(() => {
    const fetchTicketFirebaseData = async (id) => {
      setShowLoading(true)
      const ticketRef = doc(db, 'tickets', id)
      const ticketDoc = await getDoc(ticketRef)

      if (ticketDoc.data().date === dateURL && ticketDoc.data().email === emailURL) {
        setTicketData(ticketDoc.data())
      } else {
        window.location.href = '/'
      }

      setShowLoading(false)
    }

    if (ticketId) {
      fetchTicketFirebaseData(ticketId)
    }
  }, [ticketId])

  return (
    <>
      <div className="container pt-3" style={{ maxWidth: '992px' }}>
        <div className="card">
          <div className='card-title d-flex justify-content-center align-items-center'>
            <img src="parquechina.png" alt="..."
              className="card-img-top"
              style={{ objectFit: 'cover', height: '150px' }} />
            <img src="parquechina_logo.svg" alt="..."
              className='p-2 rounded-lg'
              style={{ position: 'absolute', backdropFilter: 'blur(10px)', height: '90px', maxWidth: '90%' }} />
          </div>
          <div className="card-body">
            {showLoading
              ? <>
                  <div className="d-flex justify-content-center align-items-center">
                    <h2 style={{ color: '#dd3030' }}>
                      Cargando...
                    </h2>
                    <div className="spinner-border ms-4" role="status"
                      style={{ color: '#dd3030' }}>
                      <span className="visually-hidden">Cargando...</span>
                    </div>
                  </div>
                </>
              : <>
                  {ticketData && (
                    <>
                      {ticketData.status === 'Pagada' && (
                        <>
                          <div className="alert alert-success" role="alert">
                            <h3 className="mb-3 text-center">
                              ¡Hemos confirmado tus entradas!
                            </h3>

                            <p>
                              Hola <b>{`${ticketData.firstName} ${ticketData.lastName}`}</b>, {' '}
                              hemos confirmado la cantidad de <b>{ticketData.numberOfTickets}</b> entrada(s) {' '}
                              para el día <b>{ticketData.date}</b>.
                            </p>

                            <p>
                              Enviaremos toda la información a tu correo electrónico {' '}
                              <b>{ticketData.email}</b>.
                            </p>

                            <p>
                              Ante cualquier duda, puedes contactarnos mediante el correo contacto@parquechina.cl.
                            </p>
                          </div>
                        </>
                      )}

                      {ticketData.status === 'Rechazada' && (
                        <>
                          <div className="alert alert-warning" role="alert">
                            <h3 className="mb-3 text-center">
                              ¡Tu pago ha sido rechazado!
                            </h3>

                            <p>
                              Por alguna razón tu pago ha sido rechazado por Transbank, no se ha realizado ningún cargo a tu cuenta.
                            </p>
                            <p>
                              Puedes volver a intentarlo presionando el enlace {'"'}Volver a Parque China{'"'}.
                            </p>
                            <p>
                              Ante cualquier duda, puede contactarse al correo contacto@parquechina.cl
                            </p>
                          </div>
                        </>
                      )}

                      {ticketData.status === 'Error de formulario' && (
                        <>
                          <div className="alert alert-warning" role="alert">
                            <h3 className="mb-3 text-center">
                              ¡Ha ocurrido un error en el sistema!
                            </h3>

                            <p>
                              Por alguna razón ha ocurrido un error en el sistema de Transbank, no se ha realizado ningún cargo a tu cuenta.
                            </p>
                            <p>
                              Puedes volver a intentarlo presionando el enlace {'"'}Volver a Parque China{'"'}.
                            </p>
                            <p>
                              Ante cualquier duda, puede contactarse al correo contacto@parquechina.cl
                            </p>
                          </div>
                        </>
                      )}

                      {ticketData.status === 'Timeout' && (
                        <>
                          <div className="alert alert-warning" role="alert">
                            <h3 className="mb-3 text-center">
                              ¡Tiempo de espera agotado!
                            </h3>

                            <p>
                              Se acabó el tiempo para realizar el pago en Transbank, no se ha realizado ningún cargo a tu cuenta.
                            </p>
                            <p>
                              Puedes volver a intentarlo presionando el enlace {'"'}Volver a Parque China{'"'}.
                            </p>
                            <p>
                              Ante cualquier duda, puede contactarse al correo contacto@parquechina.cl
                            </p>
                          </div>
                        </>
                      )}

                      {ticketData.status === 'Anulada' && (
                        <>
                          <div className="alert alert-warning" role="alert">
                            <h3 className="mb-3 text-center">
                              ¡Tu pago ha sido anulado!
                            </h3>

                            <p>
                              Anulaste la compra Transbank, no se ha realizado ningún cargo a tu cuenta.
                            </p>
                            <p>
                              Puedes volver a intentarlo presionando el enlace {'"'}Volver a Parque China{'"'}.
                            </p>
                            <p>
                              Ante cualquier duda, puede contactarse al correo contacto@parquechina.cl
                            </p>
                          </div>
                        </>
                      )}

                      {ticketData.status !== 'Pagada' &&
                        ticketData.status !== 'Rechazada' &&
                        ticketData.status !== 'Error de formulario' &&
                        ticketData.status !== 'Timeout' &&
                        ticketData.status !== 'Anulada' && (
                        <>
                          <div className="col-md-12 mb-3">
                            {JSON.stringify(ticketData, null, 2) }
                          </div>
                        </>
                      )}

                      <div className="col-md-12">
                        {ticketData.status === 'Pagada' && (
                          <a href={`/comprobante?id=${ticketId}&dt=${dateURL}&em=${emailURL}`}
                            target='_blank' rel="noreferrer"
                            className="btn btn-lg btn-primary w-100 mb-2"
                            style={{ backgroundColor: '#dd3030', borderColor: '#dd3030' }} >
                            Descargar comprobante
                          </a>
                        )}

                        <button type="button" className="btn btn-link w-100"
                          onClick={() => { window.location.href = 'http://parquechina.cl' }} >
                          Volver a Parque China
                        </button>
                      </div>
                    </>
                  )}
                </>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default Confirmation
